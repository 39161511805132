import React, { useState, useEffect } from 'react';
import RelatedBookColumn from '../components/containers/UserRelatedBookColumn';
import SpinnerDot from './Spinners/AccountSpinnerDot';
import { URL_API } from 'gatsby-env-variables';

const UserRelatedBooks = (props) => {
  const [books, setBooks] = useState([]);
  const [booksCount, setBooksCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);

  const renderBooks = books.map((book) => {
    return <RelatedBookColumn book={book} key={book.id} />;
  });

  const retrieveBooks = async () => {
    const response = fetch(`${URL_API}/book`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        //'Access-Control-Allow-Origin': "*",
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
        'Access-Control-Allow-Headers':
          'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  useEffect(() => {
    const getBooks = async () => {
      const selectedBooks = await retrieveBooks();
      setBooks(selectedBooks);
      setBooksCount(selectedBooks.length);
      setPageLoading(false);
    };
    getBooks();
  }, []);

  return (
    <>
      {pageLoading ? (
        <div className="col-span-12">
          <SpinnerDot className="flex h-full w-full bg-transparent" />
        </div>
      ) : booksCount > 0 ? (
        renderBooks
      ) : (
        <div className="col-span-12">
          <h3 className="text-xl mt-5 mx-5">No Books Found...</h3>
        </div>
      )}
    </>
  );
};

export default UserRelatedBooks;
