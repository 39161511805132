import React, { useState, useEffect } from 'react';
import SpinnerDot from './Spinners/AccountSpinnerDot';
import { URL_API, URL_BASE, URL_ASSETS } from 'gatsby-env-variables';
import img_blank_1000x1000 from '../images/blank_1000x1000.png';

const UserFreeBooks = () => {
  const [books, setBooks] = useState([]);
  const [booksCount, setBooksCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [limit, setLimit] = useState(1);
  const [offset, setOffset] = useState(0);

  const retrieveBooks = async () => {
    const response = fetch(`${URL_API}/book`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        //'Access-Control-Allow-Origin': "*",
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
        'Access-Control-Allow-Headers':
          'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  useEffect(() => {
    const getBooks = async () => {
      const selectedBooks = await retrieveBooks();
      setBooks(selectedBooks);
      setBooksCount(selectedBooks.length);
      setPageLoading(false);
    };
    getBooks();
  }, []);

  const data = books.slice(offset, limit);

  return (
    <>
      {pageLoading ? (
        <div className="col-span-12 pt-6">
          <SpinnerDot className="flex h-full w-full bg-white" />
        </div>
      ) : booksCount > 0 ? (
        <>
          <div className="w-full relative">
            <div className=" grid grid-cols-12 max-w-screen-xl mx-auto">
              {data.map(({ id, name, main_image_url, subtitle, status }) => (
                <div className="xl:col-span-4 col-span-12 text-center px-7">
                  <a href={`/book?id=${id}`}>
                    <div className="hover:bg-#F2F2F2 transition-all duration-500 bg-white pt-4 pb-2 mt-6">
                      <div
                        className="thumbnail-cover"
                        style={{
                          backgroundImage: `url(${URL_ASSETS}${main_image_url})`,
                        }}>
                        <img src={img_blank_1000x1000} className="thumbnail-placeholder" />
                      </div>
                      <p className="text-md font-opensans mt-5 text-gray-500">Read 24 hours ago</p>
                      <h3 className="font-rokkit text-2xl font-bold">{name}</h3>
                      <h4 className="font-rokkit text-xl font-bold mb-1">{subtitle}</h4>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className="col-span-12">
          <h3 className="text-xl mt-5">No Books Found...</h3>
        </div>
      )}
    </>
  );
};

export default UserFreeBooks;
