import React from "react";
import { SpinnerDotted } from 'spinners-react';

const AccountSpinnerDot = (props) => {
  return (
    <div className={props.className}>
        <div className="m-auto">
          <SpinnerDotted size={50} thickness={140} speed={100} color="rgba(153, 127, 75, 1)" />
        </div>
    </div>
  );
};

export default AccountSpinnerDot;