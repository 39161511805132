import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import { StaticImage, getImage } from 'gatsby-plugin-image';
import Navigation from '../components/navigation/navigation';
import { navigate } from 'gatsby-link';
import Footer from '../components/Footer';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';
import UserBooks from '../components/UserBooks';
import UserFreeBooks from '../components/UserFreeBooks';
import UserRelatedBooks from '../components/UserRelatedBooks';
import UserFreeRelatedBooks from '../components/UserFreeRelatedBooks';
import { Dialog, Transition } from '@headlessui/react';
import { IoMdClose } from 'react-icons/io';
import { useCookies } from 'react-cookie';
import OGImage from '../images/favicon-black.png';
import Moment from 'moment';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { URL_API, URL_ASSETS } from 'gatsby-env-variables';
import Resizer from 'react-image-file-resizer';

const initialformData = Object.freeze({
  avatar: '',
  display_name: '',
  name: '',
  phone_number: '',
  email: '',
  password: '',
  new_password: '',
  old_password: '',
  password_confirmation: '',
  payment_type: '',
  payment_status: '',
});

const AccountRazorPayPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        account_bg: file(relativePath: { eq: "account-bg.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        popup_bg: file(relativePath: { eq: "account-popup-bg.png" }) {
          childImageSharp {
            gatsbyImageData(width: 639, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    `
  );

  const [formData, setFormData] = useState(initialformData);
  const [isAccountTab, setIsAccountTab] = useState(true);
  const [isBooksTab, setIsBooksTab] = useState(false);
  const [isSubscriptionTab, setIsSubscriptionTab] = useState(false);
  const [formStatus, setFormStatus] = useState('standby');
  const [avatarStatus, setAvatarStatus] = useState('standby');
  const [currentUser, setCurrentUser] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCancel, setIsOpenCancel] = useState(false);
  const bg_account = getImage(data.account_bg);
  const bg_popup = getImage(data.popup_bg);
  // Use like this: bg-accounts-bg bg-cover bg-center
  const accountBg = convertToBgImage(bg_account);
  const [cookies, removeCookies, setCookie] = useCookies(['loggedIn', 'currentUser']);
  const [subscriptionEnd, setSubscriptionEnd] = useState();
  const [NewPasswordShown, setNewPasswordShown] = useState(false);
  const [NewConfirmPasswordShown, setNewConfirmPasswordShown] = useState(false);

  const togglePasswordNew = () => {
    // When the handler is invoked
    // inverse the boolean state of NewPasswordShown
    setNewPasswordShown(!NewPasswordShown);
  };

  const togglePasswordConfirmNew = () => {
    // When the handler is invoked
    // inverse the boolean state of NewPasswordShown
    setNewConfirmPasswordShown(!NewConfirmPasswordShown);
  };

  const url = typeof window !== 'undefined' ? window.location.origin : '';

  function accountTab(e) {
    setIsAccountTab(true);
    setIsBooksTab(false);
    setIsSubscriptionTab(false);
  }

  function booksTab(e) {
    setIsAccountTab(false);
    setIsBooksTab(true);
    setIsSubscriptionTab(false);
  }

  function subscriptionTab(e) {
    setIsAccountTab(false);
    setIsBooksTab(false);
    setIsSubscriptionTab(true);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setFormStatus('loading');
    if (currentUser) {
      if (formData.display_name !== '' && formData.name !== '' && formData.email !== '' && formData.phone_number !== '') {
        e.preventDefault();
        const response = await fetch(`${URL_API}/user/` + currentUser, {
          method: 'PUT', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, cors, *same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'omit', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json',
          },
          redirect: 'follow', // manual, *follow, error
          referrer: 'client', // no-referrer, *client
          body: JSON.stringify(formData), // body data type must match "Content-Type" header
        });

        const json = await response.json();

        if (json.status === 'Success') {
          alert('Profile updated successful');
          setFormStatus('standby');
          // sessionStorage.setItem("currentUser", JSON.stringify(json.data));
          setCookie('currentUser', JSON.stringify(json.data.id), { path: '/' });
        } else {
          alert('Profile update failed');
          setFormStatus('standby');
          //console.log(json);
        }
      } else {
        alert('Please fill up fields');
      }
    }
  }

  useEffect(() => {
    setCurrentUser(cookies.currentUser);
    const loggedIn = cookies.loggedIn;
    if (loggedIn != 'true') {
      navigate('/login');
    }
  }, []);

  async function updatePassword(e) {
    e.preventDefault();
    setFormStatus('loading');
    if (currentUser) {
      if (formData.old_password == formData.password && formData.new_password == formData.new_password_confirm) {
        e.preventDefault();
        const response = await fetch(`${URL_API}/user/` + currentUser, {
          method: 'PUT', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, cors, *same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'omit', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json',
          },
          redirect: 'follow', // manual, *follow, error
          referrer: 'client', // no-referrer, *client
          body: JSON.stringify(formData), // body data type must match "Content-Type" header
        });

        const json = await response.json();
        console.log('Json status', json.status);
        if (json.status === 'Success') {
          alert('Password updated successful');
          setFormStatus('standby');
          setCookie('currentUser', JSON.stringify(json.data.id), { path: '/' });
        } else {
          alert('Password update failed');
          setFormStatus('standby');
        }
      } else {
        alert("Password don't match");
      }
    }
  }

  async function updateAvatar(e) {
    e.preventDefault();
    setAvatarStatus('loading');
    if (formData.display_name !== '' && formData.name !== '' && formData.email !== '') {
      e.preventDefault();
      const response = await fetch(`${URL_API}/user/` + currentUser, {
        method: 'PUT', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'omit', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow', // manual, *follow, error
        referrer: 'client', // no-referrer, *client
        body: JSON.stringify(formData), // body data type must match "Content-Type" header
      });

      const json = await response.json();

      if (json.status === 'Success') {
        alert('Profile picture updated successful');
        setAvatarStatus('standby');
        // sessionStorage.setItem("currentUser", JSON.stringify(json.data));
        setCookie('currentUser', JSON.stringify(json.data.id), { path: '/' });
      } else {
        alert('Profile picture update failed');
        setAvatarStatus('standby');
        //console.log(json);
      }
    } else {
      alert('Please select a picture fill up fields');
    }
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const logOut = (e) => {
    removeCookies('loggedIn', '', { path: '/' });
    removeCookies('currentUser', '', { path: '/' });
    navigate('/login');
  };

  const retrieveUserData = async () => {
    if (currentUser) {
      const response = fetch(`${URL_API}/user/` + currentUser, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'omit', // include, *same-origin, omit
        headers: {
          //'Access-Control-Allow-Origin': "*",
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': true,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
          'Access-Control-Allow-Headers':
            'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
        },
        redirect: 'follow', // manual, *follow, error
        referrer: 'client', // no-referrer, *client
      })
        .then((response) => {
          return response.json();
        })
        .then((events) => {
          return events.data;
        });
      return response;
    }
  };

  const cancelSubscription = async () => {
    if (currentUser) {
      const response = await fetch(`${URL_API}/user/` + currentUser + '/subscription/cancel', {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'omit', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow', // manual, *follow, error
        referrer: 'client', // no-referrer, *client
        body: JSON.stringify({ id: currentUser }), // body data type must match "Content-Type" header
      });

      const json = await response.json();

      if (json.status === 'Success') {
        alert('Subscription Cancelled');
        window.location.reload();
      } else {
        alert('Cancellation Failed');
      }
    }
  };

  useEffect(() => {
    const getUser = async () => {
      const selectedUser = await retrieveUserData();
      if (selectedUser) {
        setFormData({
          ...formData,
          avatar: selectedUser.avatar,
          display_name: selectedUser.display_name,
          name: selectedUser.name,
          phone_number: selectedUser.phone_number,
          email: selectedUser.email,
          password: selectedUser.password,
          new_password: selectedUser.new_password,
          old_password: selectedUser.old_password,
          new_password_confirm: selectedUser.new_password_confirm,
          payment_type: selectedUser.payment_type,
          payment_status: selectedUser.payment_status,
          payment_start: selectedUser.payment_start,
          payment_end: selectedUser.payment_end,
        });
      }
    };
    getUser();
  }, [currentUser]);

  const hiddenFileInput = React.useRef(null);

  const handleChangeImage = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChangeProfilePic = async (event) => {
    event.preventDefault();

    try {
      const file = event.target.files[0];
      const imageTry = await resizeMainImgFile(file);
      setFormData({
        ...formData,
        avatar: imageTry,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const resizeMainImgFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        800,
        800,
        'JPEG',
        90,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModalCancel() {
    setIsOpenCancel(false);
  }

  function openModalCancel() {
    setIsOpenCancel(true);
  }

  useEffect(() => {
    if (formData.payment_start) {
      let payment_end = new Date(formData.payment_start);
      if (formData.payment_type == 'Monthly') {
        payment_end = payment_end.setMonth(payment_end.getMonth() + 1);
        const end_subscription = Moment(payment_end).format('MM-DD-YYYY');
        setSubscriptionEnd(end_subscription);
      } else if (formData.payment_type == 'Yearly') {
        payment_end = payment_end.setMonth(payment_end.getMonth() + 12);
        const end_subscription = Moment(payment_end).format('MM-DD-YYYY');
        setSubscriptionEnd(end_subscription);
      } else {
        setSubscriptionEnd('');
      }
    }
  }, [formData.payment_start]);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${url}/account`} />
        <meta http-equiv="Content-Type" content="text/xml; charset=UTF-8" />
        <meta property="og:title" content="Account | Girar World" />
        <meta property="description" content="Girar, go on a journey" />
        <meta property="og:description" content="Girar, go on a journey" />
        <meta property="og:url" content={`${url}/account`} />
        <meta property="og:image" content={`${url}${OGImage}`} />
        <meta name="twitter:url" content={`${url}/account`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@WeltgeistKiran" />
        <meta name="twitter:title" content="Account | Girar World" />
        <meta name="twitter:description" content="Girar, go on a journey" />
        <meta property="twitter:image" content={`${url}${OGImage}`} />
        <meta property="og:type" content="website" />
        <title> Account | Girar World</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Rokkitt:wght@400;500;600;700;800;900&family=Telex&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div className="xl:hidden">
        <Navigation />
      </div>
      <BackgroundImage
        Tag="section"
        className="bg-center"
        // Spread bgImage into BackgroundImage:
        {...accountBg}>
        <section className="h-full w-full xl:pt-0 pt-20 xl:pb-20 pb-10">
          <div className="grid grid-cols-12 xl:px-14 px-20">
            <div className="col-span-4 mb-14 xl:block hidden pt-10">
              <ul className="inline-block">
                <Link to="/">
                  <StaticImage
                    className="w-full xl:max-w-[150px] max-w-[100px] mx-auto"
                    src={'../../images/logo-white.png'}
                    formats={['auto', 'webp', 'avif']}
                    width={500}
                    alt="Girar Logo"
                    placeholder="blurred"
                  />
                </Link>
              </ul>
            </div>
            <div className="col-span-8 mb-10 text-right xl:block hidden pt-10">
              <ul className="inline-block">
                <li className="inline-block uppercase text-white w-28">
                  <Link to="/books">Books</Link>
                </li>
                <li className="inline-block uppercase text-white w-28">
                  <Link to="/account">Account</Link>
                </li>
                {currentUser ? (
                  <li className="inline-block uppercase text-white w-44">
                    <button
                      className="xl:px-5 ml-10 py-1 px-5 button-white rounded-md bottom-10 font-bold font-rokkit transition-all duration-500"
                      onClick={logOut}>
                      LOGOUT
                    </button>
                  </li>
                ) : (
                  <Link to="/login">
                    <button className="xl:px-5 ml-10 py-1 px-5 button-white rounded-md bottom-10 font-bold font-rokkit transition-all duration-500">
                      LOGIN
                    </button>
                  </Link>
                )}
              </ul>
            </div>
          </div>
          <div className="grid grid-cols-12 max-w-screen-2xl mx-auto xl:px-14 px-5">
            <div className="col-span-12 flex items-center xl:mb-10 mb-0">
              <h2 className="text-white xl:text-4xl text-3xl tracking-1px font-bold inline-block">Account</h2>
              <span className="bg-orange-100 h-1 w-10 mt-3 ml-5 inline-block"></span>
            </div>
            <div className="xl:col-span-2 col-span-12 pt-8">
              <ul>
                <li
                  className="font-sans text-lg text-white mb-5 cursor-pointer hover:underline transition-all duration-500 font-bold"
                  onClick={accountTab}>
                  Profile
                </li>
                <li
                  className="font-sans text-lg text-white mb-5 cursor-pointer hover:underline transition-all duration-500 font-bold"
                  onClick={booksTab}>
                  Books
                </li>
                <li
                  className="font-sans text-lg text-white mb-5 cursor-pointer hover:underline transition-all duration-500 font-bold"
                  onClick={subscriptionTab}>
                  Manage Subscription
                </li>
              </ul>
            </div>
            {isAccountTab && (
              <div className="xl:col-span-10 col-span-12 py-10 mt-5 xl:px-10 px-5 xl:mx-5 bg-white rounded-xl">
                <div className="grid grid-cols-12 border-b-2">
                  <div className="col-span-12 xl:px-8 xl:text-left text-center">
                    <h2 className="text-2xl font-bold mb-5">Profile</h2>
                  </div>
                  <div className="xl:block xl:flex-start flex flex-col col-span-12 pb-10 px-8">
                    {formData.avatar ? (
                      <div className="inline-block align-middle">
                        <img
                          src={`${URL_ASSETS}/${formData.avatar}`}
                          formats={['auto', 'webp', 'avif']}
                          width={91}
                          alt="avatar image"
                          className="mx-auto cursor-pointer"
                          onClick={handleChangeImage}
                          placeholder="blurred"
                        />
                        <input type="file" ref={hiddenFileInput} style={{ display: 'none' }} onChange={handleChangeProfilePic} />
                      </div>
                    ) : (
                      <>
                        <StaticImage
                          src={'../images/account-avatar-img.png'}
                          formats={['auto', 'webp', 'avif']}
                          width={91}
                          alt="avatar image"
                          className="mx-auto cursor-pointer"
                          onClick={handleChangeImage}
                        />
                        <input type="file" ref={hiddenFileInput} style={{ display: 'none' }} onChange={handleChangeProfilePic} />
                      </>
                    )}
                    {avatarStatus == 'standby' ? (
                      <button
                        className="xl:px-8 py-3 px-4 font-rokkit xl:mt-4 mt-7 xl:ml-10 xl:mr-3 mx-auto border-2 text-xl xl:w-auto bg-white border-black rounded-lg uppercase font-bold"
                        onClick={updateAvatar}>
                        Update
                      </button>
                    ) : (
                      <button
                        className="xl:px-8 py-3 px-4 font-rokkit xl:mt-4 mt-7 xl:ml-10 xl:mr-3 mx-auto border-2 text-xl xl:w-auto bg-white border-black rounded-lg uppercase font-bold"
                        onClick={updateAvatar}>
                        Updating
                      </button>
                    )}
                  </div>
                </div>

                <form id="account-form" className="w-full col-span-12 border-b-2">
                  <div className="xl:grid grid-cols-12 gap-x-8 gap-y-4 pt-10 xl:px-10 pb-10">
                    <div className="xl:col-span-6 col-span-12">
                      <label htmlFor="d-name" className="font-bold">
                        Display Name
                      </label>
                      <input
                        id="d-name"
                        name="display_name"
                        type="text"
                        className="rounded-md border-black border w-full h-12 px-5 mb-3 focus:outline-none focus:shadow-none pb-1 bg-transparent"
                        required
                        onChange={handleChange}
                        value={formData.display_name}
                      />
                    </div>
                    <div className="xl:col-span-6 col-span-12">
                      <label htmlFor="f-name" className="font-bold">
                        Full Name
                      </label>
                      <input
                        id="f-name"
                        type="text"
                        name="full_name"
                        className="rounded-md border-black border w-full h-12 px-5 mb-3 focus:outline-none focus:shadow-none pb-1 bg-transparent"
                        required
                        onChange={handleChange}
                        value={formData.name}
                      />
                    </div>
                    <div className="xl:col-span-6 col-span-12">
                      <label htmlFor="email-add" className="font-bold">
                        Email Address
                      </label>
                      <input
                        id="email-add"
                        type="email"
                        name="email"
                        className="rounded-md border-black border w-full h-12 px-5 mb-3 focus:outline-none focus:shadow-none pb-1 bg-transparent"
                        required
                        onChange={handleChange}
                        value={formData.email}
                      />
                    </div>
                    <div className="xl:col-span-6 col-span-12">
                      <label htmlFor="telepone" className="font-bold">
                        Phone Number
                      </label>
                      <input
                        id="telephone"
                        type="phone"
                        name="phone_number"
                        className="rounded-md border-black border w-full h-12 px-5 mb-3 focus:outline-none focus:shadow-none pb-1 bg-transparent"
                        required
                        onChange={handleChange}
                        value={formData.phone_number}
                      />
                    </div>
                  </div>
                </form>
                <div className="grid grid-cols-12 border-b-2 xl:py-10 py-6">
                  <div className="col-span-12 px-9">
                    <h3 className="font-opensans text-black font-normal text-lg">Need to change password?</h3>
                  </div>
                  <div className="xl:col-span-6 xl:pl-9 col-span-12 xl:text-left text-center mt-3 xl:mb-0 mb-3">
                    <button
                      className="uppercase border-orange-150 rounded-md border-4 text-brown-50 font-rokkit text-xl  px-4 py-3 font-bold bg-white-rock  shadow-inner2 hover:bg-limed-oak-550 transition-all duration-500"
                      onClick={openModal}>
                      Reset Password
                    </button>
                  </div>
                  <div className="xl:col-span-6 xl:pr-9 col-span-12 xl:text-right text-center mt-3">
                    {formStatus == 'standby' ? (
                      <button
                        className="uppercase border-orange-150 rounded-md border-4 text-brown-50 font-rokkit text-xl  px-4 py-3 font-bold bg-white-rock  shadow-inner2 hover:bg-limed-oak-550 transition-all duration-500"
                        onClick={handleSubmit}>
                        Save Changes
                      </button>
                    ) : (
                      <button
                        className="uppercase border-orange-150 rounded-md border-4 text-brown-50 font-rokkit text-xl  px-4 py-3 font-bold bg-white-rock  shadow-inner2 hover:bg-limed-oak-550 transition-all duration-500"
                        onClick={handleSubmit}>
                        Saving Changes
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}

            <Transition appear show={isOpen} as={Fragment}>
              <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
                <div className="min-h-screen px-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                  </Transition.Child>

                  {/* This element is to trick the browser into centering the modal contents. */}
                  <span className="inline-block h-screen align-middle" aria-hidden="true">
                    &#8203;
                  </span>

                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95">
                    <div className="inline-block w-full max-w-xl py-10 xl:px-12 px-5 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                      <div>
                        <IoMdClose className="ml-auto w-5 h-5 text-black cursor-pointer" onClick={closeModal} />
                      </div>
                      <div className="mt-2">
                        <form className="w-full" onSubmit={updatePassword}>
                          <div className="grid grid-cols-12">
                            <div className="col-span-12 pt-5">
                              <label htmlFor="old-pass" className="font-bold">
                                Old Password
                              </label>
                              <input
                                id="old-pass"
                                name="old_password"
                                type="password"
                                className="rounded-md border-black border w-full h-12 px-5 mb-3 focus:outline-none focus:shadow-none pb-1 bg-transparent"
                                required
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-span-12 relative">
                              <label htmlFor="new-pass" className="font-bold">
                                New Password
                              </label>
                              <input
                                id="new-pass"
                                type={NewPasswordShown ? 'text' : 'password'}
                                name="new_password"
                                className="rounded-md border-black border w-full h-12 px-5 mb-3 focus:outline-none focus:shadow-none pb-1 bg-transparent"
                                required
                                onChange={handleChange}
                              />
                              <div className="absolute top-[40px] right-[30px]">
                                {NewPasswordShown ? (
                                  <AiFillEye onClick={togglePasswordNew} className="show-pass-btn" />
                                ) : (
                                  <AiFillEyeInvisible onClick={togglePasswordNew} className="show-pass-btn" />
                                )}
                              </div>
                            </div>
                            <div className="col-span-12 pb-5 relative">
                              <label htmlFor="confirm-pass" className="font-bold">
                                Confirm New Password
                              </label>
                              <input
                                id="confirm-pass"
                                type={NewConfirmPasswordShown ? 'text' : 'password'}
                                name="new_password_confirm"
                                className="rounded-md border-black border w-full h-12 px-5 mb-3 focus:outline-none focus:shadow-none pb-1 bg-transparent"
                                required
                                onChange={handleChange}
                              />

                              <div className="absolute top-[40px] right-[30px]">
                                {NewConfirmPasswordShown ? (
                                  <AiFillEye onClick={togglePasswordConfirmNew} className="show-pass-btn" />
                                ) : (
                                  <AiFillEyeInvisible onClick={togglePasswordConfirmNew} className="show-pass-btn" />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="xl:col-span-12 text-center mt-3">
                            <button className="uppercase border-orange-150 rounded-md border-4 text-brown-50 font-rokkit text-xl  px-4 py-3 font-bold bg-white-rock  shadow-inner2 hover:bg-limed-oak-550 transition-all duration-500">
                              Reset Password
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition>

            <Transition appear show={isOpenCancel} as={Fragment}>
              <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModalCancel}>
                <div className="min-h-screen px-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                  </Transition.Child>

                  {/* This element is to trick the browser into centering the modal contents. */}
                  <span className="inline-block h-screen align-middle" aria-hidden="true">
                    &#8203;
                  </span>

                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95">
                    <div className="inline-block w-full max-w-xl py-10 xl:px-12 px-5 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                      <div>
                        <IoMdClose className="ml-auto w-5 h-5 text-black cursor-pointer" onClick={closeModalCancel} />
                      </div>
                      <div className="mt-2 text-center">
                        <p className="font-opensans font-2xl font-medium mb-5">Are you sure do you want to cancel your subscription?</p>
                        <div className="text-center">
                          <button
                            onClick={cancelSubscription}
                            className="uppercase border-orange-150 rounded-md border-4 text-brown-50 font-rokkit text-xl mx-2 px-4 py-2 mb-5 font-bold bg-white-rock  shadow-inner2 hover:bg-limed-oak-550 transition-all duration-500 w-1/4">
                            Yes
                          </button>
                          <button
                            onClick={closeModalCancel}
                            className="uppercase border-orange-150 rounded-md border-4 text-brown-50 font-rokkit text-xl mx-2 px-4 py-2 font-bold bg-white-rock  shadow-inner2 hover:bg-limed-oak-550 transition-all duration-500 w-1/4">
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition>

            {isBooksTab && (
              <div className="xl:col-span-10 col-span-12 py-10 xl:mx-5 mt-5 xl:px-10 px-5 bg-white rounded-xl">
                <div className="grid grid-cols-12 border-b-2">
                  <div className="col-span-12 xl:px-8 xl:text-left text-center">
                    <h2 className="text-2xl font-bold mb-5">Books</h2>
                  </div>
                  <div className="xl:col-span-2 text-center col-span-12 xl:pb-10 pb-5">
                    {formData.avatar ? (
                      <img
                        src={`${URL_ASSETS}/${formData.avatar}`}
                        formats={['auto', 'webp', 'avif']}
                        width={91}
                        alt="avatar image"
                        className="mx-auto"
                        placeholder="blurred"
                      />
                    ) : (
                      <StaticImage
                        src={'../images/account-avatar-img.png'}
                        formats={['auto', 'webp', 'avif']}
                        width={91}
                        alt="avatar image"
                        className="mx-auto"
                        placeholder="blurred"
                      />
                    )}
                  </div>
                  <div className="xl:col-span-10 col-span-12 xl:pt-2 xl:pb-0 pb-5 xl:text-left text-center">
                    <p className="font-rokkit text-2xl font-bold">{formData.name}</p>
                    <p className="font-rokkit text-lg font-semibold">{formData.email}</p>
                  </div>
                </div>
                <div className="border-b-2 pb-7">
                  {formData.payment_type == 'Free' ||
                  formData.payment_status == 'Cancelled' ||
                  formData.payment_type == '' ||
                  formData.payment_status == '' ? (
                    <UserFreeBooks />
                  ) : (
                    <UserBooks />
                  )}
                </div>
                <div className="grid grid-cols-12 py-7">
                  <div className="col-span-12 flex items-center px-8">
                    <h3 className="xl:text-2xl text-lg uppercase font-bold font-rokkit inline-block mr-4">Related Stories</h3>
                    <span className="bg-orange-100 h-1 mt-1 w-12 inline-block"></span>
                  </div>
                </div>
                <div className="grid grid-cols-12 px-10">
                  {formData.payment_type == '' || formData.payment_status == 'Cancelled' || formData.payment_type == 'Free' ? (
                    <UserFreeRelatedBooks />
                  ) : (
                    <UserRelatedBooks />
                  )}
                </div>
              </div>
            )}
            {isSubscriptionTab && (
              <div className="xl:col-span-10 col-span-12 py-10 xl:px-10 px-5 xl:mx-5 mt-5 bg-white rounded-xl">
                <div className="grid grid-cols-12">
                  <div className="col-span-12 xl:px-8 xl:text-left text-center">
                    <h2 className="text-2xl text-black font-bold mb-5">Manage Subscription</h2>
                  </div>
                  <div className="col-span-12 border-b-2">
                    <div className="grid grid-cols-12">
                      <div className="xl:col-span-2 text-center col-span-12 xl:pb-10 pb-5">
                        {formData.avatar ? (
                          <img
                            src={`${URL_ASSETS}/${formData.avatar}`}
                            formats={['auto', 'webp', 'avif']}
                            width={91}
                            alt="avatar image"
                            className="mx-auto"
                            placeholder="blurred"
                          />
                        ) : (
                          <StaticImage
                            src={'../images/account-avatar-img.png'}
                            formats={['auto', 'webp', 'avif']}
                            width={91}
                            alt="avatar image"
                            className="mx-auto"
                            placeholder="blurred"
                          />
                        )}
                      </div>
                      <div className="xl:col-span-10 col-span-12 text-center xl:pb-0 pb-5 xl:text-left xl:pt-2">
                        <p className="font-rokkit text-2xl font-bold">{formData.name}</p>
                        <p className="font-rokkit text-lg font-semibold">{formData.email}</p>
                      </div>
                    </div>
                  </div>
                  {formData.payment_status === 'Active' ? (
                    <>
                      <div className="col-span-12 w-full mt-7 py-10 max-w-3xl mx-auto px-10 bg-#F7F7F7 rounded-md">
                        <div className="grid grid-cols-12">
                          <div className="col-span-6">
                            <h3 className="font-opensans text-2xl font-semibold text-outer-space">{formData.payment_type}</h3>
                            <p className="font-opensans text-base font-normal text-#848789">Until {subscriptionEnd}</p>
                          </div>
                          <div className="col-span-6">
                            <div className="p-1 border-#DBB367 border xl:w-20 w-auto ml-auto text-center rounded-md">
                              <p className="font-opensans text-base font-normal text-outer-space">{formData.payment_status}</p>
                            </div>
                          </div>
                          <div className="col-span-12 border border-#DBB367 py-2 px-3 mt-3">
                            <div className="grid grid-cols-12">
                              <div className="col-span-6">
                                <p className="text-outer-space font-base font-opensans font-normal">Next payment</p>
                              </div>
                              <div className="col-span-6 text-right">
                                <p className="text-outer-space font-base font-opensans font-normal">{subscriptionEnd}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="xl:col-span-6 col-span-12 xl:px-4.5rem xl:text-left text-center mt-5">
                        <button
                          className="uppercase border-orange-150 rounded-md border-4 text-brown-50 font-rokkit text-xl  px-4 py-3 font-bold bg-white-rock  shadow-inner2 hover:bg-limed-oak-550 transition-all duration-500"
                          onClick={openModalCancel}>
                          Cancel Subscription
                        </button>
                      </div>
                      <div className="xl:col-span-6 col-span-12 xl:px-4.5rem xl:text-left text-center mt-5">
                        <Link to="/pricing-guide-razorpay">
                          <button className="uppercase border-orange-150 rounded-md border-4 text-brown-50 font-rokkit text-xl  px-4 py-3 font-bold bg-white-rock  shadow-inner2 hover:bg-limed-oak-550 transition-all duration-500">
                            Change Subscription
                          </button>
                        </Link>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-span-12 w-full mt-7 py-10 max-w-3xl mx-auto px-10 bg-#F7F7F7 rounded-md">
                        <div className="grid grid-cols-12">
                          <div className="col-span-6">
                            <h3 className="font-opensans text-2xl font-semibold text-outer-space">Free Subscription</h3>
                          </div>
                          <div className="col-span-6">
                            <div className="p-1 border-#DBB367 border xl:w-20 w-auto ml-auto text-center rounded-md">
                              <p className="font-opensans text-base font-normal text-outer-space">Active</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-12 xl:px-4.5rem xl:text-left text-center mt-5">
                        <Link to="/pricing-guide">
                          <button className="uppercase border-orange-150 rounded-md border-4 text-brown-50 font-rokkit text-xl  px-4 py-3 font-bold bg-white-rock  shadow-inner2 hover:bg-limed-oak-550 transition-all duration-500">
                            Subscribe Now
                          </button>
                        </Link>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </section>
      </BackgroundImage>
      <Footer />
    </>
  );
};
export default AccountRazorPayPage;
