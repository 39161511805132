import React from 'react';
import { URL_BASE, URL_ASSETS } from 'gatsby-env-variables';
import img_blank_1000x1000 from '../../images/blank_1000x1000.png';

const UserRelatedBookColumn = (props) => {
  const { id, name, main_image_url, subtitle, status } = props.book;

  return (
    <div className="xl:col-span-3 col-span-12 text-center px-5 mb-5">
      {status == 'Draft' ? null : (
        <a href={`/book?id=${id}`}>
          <div
            className="thumbnail-cover"
            style={{
              backgroundImage: `url(${URL_ASSETS}${main_image_url})`,
            }}>
            <img src={img_blank_1000x1000} className="thumbnail-placeholder" />
          </div>
          <h3 className="font-rokkit text-xl text-black mt-4 font-semibold">{name}</h3>
          <h4 className="font-rokkit text-lg text-black font-semibold">{subtitle}</h4>
        </a>
      )}
    </div>
  );
};

export default UserRelatedBookColumn;
